import { useTranslation } from '../../lib/i18n';

const EventMeta = ({ event, className = '' }) => {
  const items = [];
  const { attrT } = useTranslation();
  const location = attrT(event.metadata, 'ort');
  const date = attrT(event.metadata, 'datum');

  if (location) {
    items.push(location);
  }

  if (date) {
    // const [day, month, year] = date.split('.');
    items.push(date);
  }

  // if (event.metadata.start) {
  //   items.push(
  //     `${event.metadata.start}${
  //       event.metadata.ende ? ' - ' + event.metadata.ende : ''
  //     }`,
  //   );
  // }

  return <div className={className}>{items.join(' | ')}</div>;
};

export default EventMeta;
