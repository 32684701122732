export const RichText = ({ className = '', elements, ...props }) => (
  <>
    {elements && (
      <div
        {...props}
        className={`cmsRichText ${className}`}
        dangerouslySetInnerHTML={{
          __html: elements,
        }}
      />
    )}
  </>
);
