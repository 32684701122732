import { only } from '../../lib/utils';

export const FavAction = ({
  className = '',
  isInWishlist = false,
  onAddToWishlist = null,
  onRemoveFromWishlist = null,
  color = 'white',
}) => (
  <button
    className={`hover:bg-grey block rounded-full text-white focus:outline-none ${className}`}
    onClick={only(isInWishlist ? onRemoveFromWishlist : onAddToWishlist)}
  >
    <img
      src={
        isInWishlist
          ? `/static/fav/${color}_full.svg`
          : `/static/fav/${color}.svg`
      }
      className='mx-auto my-0'
      alt=''
      height={24}
      width={18}
    />
  </button>
);
