import { useTranslation } from '../../lib/i18n';
import ProductPreview from '../product/preview';
import EventMeta from './meta';

const EventPreview = ({ item, ...props }) => {
  const { attrT } = useTranslation();

  return (
    <ProductPreview
      item={item}
      imageClassName='rounded-lg-img rounded-lg w-full h-[215px]'
      // imageStyle={{ background: item.metadata?.['bg-color'] }}
      {...props}
    >
      <div className='w-full flex-grow'>
        <EventMeta event={item} className='bg-black text-white' />
        <p className='mt-2'>{attrT(item.metadata, 'lead')}</p>
      </div>
    </ProductPreview>
  );
};

EventPreview.isProduct = true;

export default EventPreview;
