export const COLORS_BG_COLORS_MATCHING = {
  // beige1: 'bg-beige1',
  // beige2: 'bg-beige2',
  // beige3: 'bg-beige3',
  // brown1: 'bg-green',
  // brown2: 'bg-brown2',
  // brown3: 'bg-brown3',
  // oliv1: 'bg-oliv1',
  // oliv2: 'bg-oliv2',
  // oliv3: 'bg-oliv3',
  // peach1: 'bg-peach1',
  // peach2: 'bg-peach2',
  // peach3: 'bg-peach3',
  // purple1: 'bg-purple1',
  // purple2: 'bg-purple2',
  // purple3: 'bg-purple3',
  // red1: 'bg-red1',
  // red2: 'bg-red2',
  // red3: 'bg-red3',
};

// Required. Otherwise purgeCSS removes concatenated classes
export const STRUCTURE_BG_COLORS_MATCHING = {
  // red1: 'bg-peach3', // feder
  // red2: 'bg-peach2', // wage
  // red3: 'bg-beige1', // pfeffer
  // red4: 'bg-brown3', // kg
  // white1: 'bg-oliv2', // feder
  // white2: 'bg-senf3', // wage
  // white3: 'bg-oliv1', // explosion
  // white4: 'bg-senf2', // lollipop
};

export const getBackgroundColor = (key) => {
  return STRUCTURE_BG_COLORS_MATCHING[key] || 'bg-' + key || '';
};

export const Background = ({
  // product = null,
  rounded = 't',
  className = '',
  //color = 'oliv2',
  children,
  ...props
}) => {
  // const bgColorClassName =
  //     getBackgroundColor(
  //         product.attributes['ui-color']?.valueSlug
  //             ? product.attributes['ui-color'].valueSlug
  //             : product.attributes.struktur?.valueSlug
  //     ) || `bg-${color}`;

  const bgColorClassName = `bg-white`;

  const roundedClassName = rounded ? 'rounded-' + rounded + '-lg' : '';
  return (
    <div
      className={`${bgColorClassName} ${roundedClassName} ${className}`}
      {...props}
    >
      {/* <div
                className={`absolute inset-0 ${roundedClassName} product-bg-gradient-overlay`}
            /> */}
      {children}
    </div>
  );
};
