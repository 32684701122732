import { useTranslation } from '../../lib/i18n';

export const Country = ({
  wine: { attributes },
  className = '',
  centered = true,
}) => {
  const { valT } = useTranslation();

  if (!attributes.land) {
    return <></>;
  }

  return (
    <div
      className={`flex flex-row ${
        centered ? 'justify-center' : ''
      } ${className}`}
    >
      <img
        className='mr-3 h-5 w-5'
        src={`/static/country/${attributes.land.valueSlug}.svg`}
        alt=''
      />
      <span className='enforce-one-line'>
        {valT(attributes, 'land')}, {valT(attributes, 'appellation')}
      </span>
    </div>
  );
};
