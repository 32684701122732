import { useTranslation } from '../../lib/i18n';
import ProductPreview from '../product/preview';
import { StructureIcon } from './attributes';
import { Country } from './country';
import { YearSize } from './year-size';

const WinePreview = ({ item, ...props }) => {
  const { valT } = useTranslation();
  return (
    <ProductPreview
      lead={item.brand}
      item={item}
      priceMeta={<YearSize wine={item} />}
      {...props}
    >
      <Country wine={item} />
      <div className='mt-2 flex'>
        <StructureIcon wine={item} className='mr-2 w-[20px]' />
        <span className='enforce-one-line font-black'>
          {valT(item.attributes, 'strukturtext-v2')}
        </span>
      </div>
    </ProductPreview>
  );
};

WinePreview.isProduct = true;

export default WinePreview;
