import ProductPreview from '../product/preview';

const MiscPreview = ({ item, ...props }) => (
  <ProductPreview
    item={item}
    imageClassName='rounded-lg-img rounded-lg w-full h-[215px]'
    {...props}
  />
);

MiscPreview.isProduct = true;

export default MiscPreview;
