import { useTranslation } from '../../lib/i18n';
import ProductPreview from '../product/preview';

const PackagePreview = ({ item, ...props }) => {
  const { attrT } = useTranslation();
  const lead = attrT(item.metadata, 'lead');
  return (
    <ProductPreview
      item={item}
      imageClassName='rounded-lg-img rounded-lg w-full h-[215px]'
      allowMultipleAdd={false}
      {...props}
    >
      <p className='mt-3 flex-grow border-t border-0.3 pt-4'>{lead}</p>
    </ProductPreview>
  );
};

PackagePreview.isProduct = true;

export default PackagePreview;
