import { useTranslation } from '../../lib/i18n';

export const YearSize = ({ wine, className = '' }) => {
  const { attrT } = useTranslation();
  let variantName = attrT(wine.defaultVariant, 'name');

  const jahrgang =
    wine.attributes.jahrgang && wine.attributes.jahrgang?.value != '0'
      ? wine.attributes.jahrgang.value
      : null;

  if (variantName) {
    variantName = variantName.split(', ')[0];
  }

  return (
    <div className={className}>
      {jahrgang}
      {variantName && (
        <>
          {jahrgang && <>,&nbsp;</>}
          {variantName}
        </>
      )}
    </div>
  );
};
