import Image from 'next/legacy/image';
import Link from 'next/link';
import { useTranslation } from '../../lib/i18n';
import { noChangeLoader } from '../../lib/images';

const PackageSuggestion = () => {
  const { t } = useTranslation();
  return (
    <div className={`-my-5 mx-auto max-w-[398px] px-9`}>
      <Link
        href={'/kontakt'}
        style={{
          background:
            'linear-gradient(180deg, rgba(233, 165, 136, 0.38) 0%, rgba(233, 165, 136, 0.13) 76.04%)',
        }}
        className={`relative -mx-4 flex h-full cursor-pointer flex-col justify-between overflow-hidden overflow-ellipsis pb-6 pl-6 pr-6 pt-6 text-center`}
      >
        <Image
          loader={noChangeLoader}
          className='rounded-lg-img rounded-lg lg:h-[215px]'
          src='/static/suggestion.png'
          alt=''
          width={316}
          height={215}
          layout='responsive'
          unoptimized={true}
        />
        <div className='flex flex-grow flex-col justify-between pt-5'>
          <h3 className='font-base h4 mb-2 text-black'>
            {t('package_suggestion_title')}
          </h3>
          <p className='mt-3 flex-grow border-t border-0.3 pt-4'>
            {t('package_suggestion_text')}
          </p>

          <div className='mx-auto mt-4 rounded-md bg-actionRed px-6 py-4 font-bold uppercase text-white transition duration-200 hover:bg-black focus:shadow-outline focus:outline-none'>
            {t('package_suggestion_cta')}
          </div>
        </div>
      </Link>
    </div>
  );
};

PackageSuggestion.isProduct = false;

export default PackageSuggestion;
