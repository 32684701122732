import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  calcImageProps,
  COLLECTION_PREVIEW_LOGO_SIZE,
  noChangeLoader,
  prismicLoader,
} from '../../lib/images';
import { COLORS_BG_COLORS_MATCHING } from '../product/background';
import { Country } from '../wine/country';

const CollectionPreview = ({ item: collection, className = '' }) => {
  const router = useRouter();

  const href = `/${collection.slug}`;

  const onClick = () => {
    router.push(href).then(() => window.scrollTo(0, 0));
  };

  const backgroundImageUrl = collection.heading_image
    ? `${collection.heading_image.url}&w=500&h=500&fit=crop&crop=entropy&auto=format,enhance`
    : null;

  const backgroundColor =
    !backgroundImageUrl && collection.logo
      ? COLORS_BG_COLORS_MATCHING[collection.heading_background_color]
      : '';

  return (
    <div onClick={onClick} className={`${className} preview-item`}>
      <Link
        href={href}
        className={`min-h-270px relative flex h-full cursor-pointer justify-between rounded-lg bg-white text-center`}
      >
        <div className='relative flex h-full w-full flex-col items-center justify-center gap-3'>
          <div
            className={`rounded-t-lg-first-child relative w-full rounded-t-lg text-center ${backgroundColor}`}
          >
            {backgroundImageUrl ? (
              <div className='rounded-lg-img aspect-h-9 aspect-w-16 rounded-lg'>
                <Image
                  loader={noChangeLoader}
                  src={backgroundImageUrl}
                  alt=''
                  layout='fill'
                  objectFit='cover'
                  unoptimized
                />

                {collection.logo && (
                  <div className='flex h-full items-center justify-center'>
                    <Image
                      loader={prismicLoader}
                      {...calcImageProps(
                        collection.logo,
                        COLLECTION_PREVIEW_LOGO_SIZE,
                      )}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div
                className={`aspect-h-9 aspect-w-16 relative rounded-lg bg-inputPlaceholder`}
              ></div>
            )}
          </div>

          <div className='w-full border-b border-0.3 py-3 font-bold leading-3'>
            {collection.lead ? collection.lead : <>&nbsp;</>}
          </div>

          <div className='flex flex-grow flex-col items-center justify-center py-5'>
            <h3 className='mb-4'>{collection.title}</h3>
            {collection.attributes && (
              <Country wine={collection} className='mb-4' />
            )}
          </div>
        </div>
        <div className='preview-item-border'></div>
      </Link>
    </div>
  );
};

export default CollectionPreview;
