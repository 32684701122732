import { A11y, Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';

const DEFAULT_BREAKPOINTS = {
  768: {
    slidesPerView: 2,
  },
  1024: {
    slidesPerView: 3,
  },
};

const Carousel = ({
  children,
  className = '',
  breakpoints = DEFAULT_BREAKPOINTS,
  boarders = true,
  navigation = true,
  pagination = false,
  autoplay = { delay: 5000, pauseOnMouseEnter: true, stopOnLastSlide: true },
  onSlideChange = (swiper) => {
    if (swiper.realIndex > 2) {
      swiper.autoplay.stop();
    }
  },
  ...props
}) => {
  if (!children) {
    return <></>;
  }

  return (
    <Swiper
      {...props}
      className={`${className} ${boarders ? 'swiper-slide-boarder' : ''}`}
      modules={[Navigation, Autoplay, A11y, Pagination]}
      autoplay={autoplay}
      navigation={navigation}
      breakpoints={breakpoints}
      onSlideChange={onSlideChange}
      pagination={
        pagination
          ? {
              type: 'bullets',
              clickable: true,
            }
          : false
      }
    >
      {children.map((child, key) => (
        <SwiperSlide key={key}>{child}</SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;
